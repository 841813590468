import styled from 'styled-components'
import React from 'react'

export const WHTitleText = styled.div`
	margin-bottom: 128px;

	.title {
		max-width: 100%;

		h1 {
			@media (max-width: 1250px) {
				font-size: 2.625rem;
				line-height: 3.75rem;
			}

			@media (max-width: 1024px) {
				font-size: 32px;
				line-height: 44px;
			}

			@media (max-width: 525px) {
				max-width: 322px;
				font-size: 30px;
				line-height: 44px;
			}
		}
	}

	a {
		color: inherit;
		font: inherit;
		text-transform: capitalize;
		text-decoration: underline;
	}

	@media (max-width: 1024px) {
		margin-bottom: 32px;
	}
`

export const WHImage = styled.img`
	margin-bottom: 255px;
	width: 100%;
	position: relative;

	@media (max-width: 1024px) {
		margin-bottom: 12.8em;
	}

	@media (max-width: 525px) {
		margin-bottom: 9.2em;
	}
`

export const Right = styled.div`
	.title {
		max-width: 545px;
	}

	.image-wrapp {
		margin-top: 36px;
		text-align: center;
	}

	&.custom {
		.title {
			max-width: 645px;
		}
	}

	&.powered {
		.gatsby-image-wrapper {
			max-height: unset !important;
			max-width: 58%;
			margin: 0 auto;
		}
	}

	@media (max-width: 1250px) {
		.title {
			max-width: 100%;
		}

		.image-wrapp {
			margin-top: 0;

			img {
				width: 30%;
			}
		}

		&.custom {
			.gatsby-image-wrapper {
				max-height: 100%;
				max-width: 705px;
				margin: 0 auto;
			}
		}

		&.powered {
			.gatsby-image-wrapper {
				max-width: 42%;
			}
		}
	}

	@media (max-width: 525px) {
		margin-bottom: 96px;

		.irc-content {
			margin-bottom: 32px;
		}

		.image-wrapp {
			img {
				width: 52%;
			}
		}

		&.powered {
			.gatsby-image-wrapper {
				max-width: 83%;
			}
		}
	}
`
export const Left = styled.div`
	margin-bottom: 256px;

	div.content {
		padding-right: 0;
		padding-left: 94px;

		.title {
			max-width: 100%;
		}
	}

	div.s-image {
		padding-right: 0;
		flex-basis: calc(50% - 64px);
		max-width: calc(50% - 64px);

		.image-wrapp {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&.embracing {
		div.content {
			padding-top: 120px;
			padding-bottom: 120px;
		}

		.gatsby-image-wrapper {
			width: 100% !important;
			max-height: 100% !important;
		}
	}

	@media (max-width: 1250px) {
		margin-bottom: 128px;

		div.content {
			padding: 0 !important;
			flex-basis: 100%;
			max-width: 100%;
		}

		div.s-image {
			flex-basis: 100%;
			max-width: 100%;
			order: 1;
			padding: 60px 0;

			.image-wrapp {
				margin: 0;

				.gatsby-image-wrapper {
					/* margin: 60px 0; */
					width: 75%;
					max-height: 100%;
					top: unset;
					transform: unset;
				}

				img {
					width: 30%;
				}
			}
		}
	}

	@media (max-width: 525px) {
		margin-bottom: 96px;

		div.s-image {
			padding-top: 32px;
			padding-bottom: 0;
			margin: 0;

			.image-wrapp {
				.gatsby-image-wrapper {
					width: 100%;
				}

				img {
					width: 52%;
				}
			}
		}
	}
`

export const SamePage = styled.div`
	.title {
		max-width: 1155px;
	}
`
export const DecP = styled.p`
	margin-bottom: 128px;

	@media (max-width: 525px) {
		margin-bottom: 96px;
	}
`

export const Framework = styled.div`
	margin-bottom: 256px;

	.title {
		font-size: 52px;
		line-height: 77px;
		text-align: center;
		margin-bottom: 96px;
	}

	@media (max-width: 1250px) {
		margin-bottom: 128px;

		.title {
			font-size: 2rem;
			line-height: 2.75rem;
		}
	}

	@media (max-width: 525px) {
		margin-bottom: 96px;

		.title {
			max-width: 100%;
			font-size: 24px;
			line-height: 44px;
			margin-bottom: 32px;
		}
	}
`

export const FContainer = styled.div`
	max-width: 1100px;
	margin: 0 auto;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;

	@media (max-width: 1100px) {
		display: block;
	}
`

export const FWrapper = styled.div`
	margin: 0 auto;
	text-align: center;

	&.image0 {
		max-width: 245px;
	}

	p {
		margin: 0;
		font-size: 30px;
		line-height: 60px;
		font-weight: 500;
		font-family: 'Poppins Bold';
		letter-spacing: 0.77px;
		color: white;
		mix-blend-mode: difference;
	}

	@media (max-width: 1100px) {
		margin-bottom: 30px;

		&.image0 {
			max-width: 156px;

			img {
				width: 66%;
			}
		}

		&.image2 {
			img {
				width: 295px;
			}
		}

		&.image1,
		&.image3 {
			max-width: 26px;

			img {
				width: 100%;
				transform: rotate(90deg);
			}
		}

		&.image4 {
			max-width: 190px;
			img {
				width: 66%;
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		p {
			font-size: 20px;
			line-height: normal;
		}
	}
`

/******************************************
 * Gatsby bug (file must export component)
 *****************************************/
const Style = () => {
	return <div>style</div>
}

export default Style
