import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Styles
import {
	WHTitleText,
	WHImage,
	Right,
	Left,
	SamePage,
	DecP,
	Framework,
	FWrapper,
	FContainer,
} from './style'

// Ui components
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Heading from '../../components/ui/Heading'
import Container from '../../components/ui/Container'
import ImageRightContentCta from '../../components/ui/ImageRightContentCta'
import ImageLeftContentCta from '../../components/ui/ImageLeftContentCta'
import TitleText from '../../components/ui/TitleText'
import Paragraph from '../../components/ui/Paragraph/index'

const WhiteLabel = React.memo((props) => {
	const data = useStaticQuery(graphql`
		query WhiteLabel {
			timacum {
				page(id: "5ffeb5e7c8ad8075e98523fd") {
					Title
					Seo {
						TitleTag
						MetaDescription
					}
					Fields {
						... on TIMACUM_ComponentContentTitleText {
							id
							Title
							Text
							TitleTag
						}
						... on TIMACUM_ComponentContentImage {
							id
							Image {
								url
								alternativeText
								caption
								imageFile {
									publicURL
									childImageSharp {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}
						... on TIMACUM_ComponentContentImageRightText {
							id
							GradientDark
							GradientLight
							Layout
							Text
							Title
							Image {
								url
								alternativeText
								caption
								imageFile {
									publicURL
									childImageSharp {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}

						... on TIMACUM_ComponentContentImageContentRight {
							id
							GradientDark
							GradientLight
							Layout
							Text
							Title
							Image {
								url
								alternativeText
								caption
								imageFile {
									publicURL
									childImageSharp {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}

						... on TIMACUM_ComponentContentImageContentLeft {
							id
							GradientDark
							GradientLight
							Layout
							Text
							Title
							CTA
							Link
							Image {
								url
								alternativeText
								caption
								imageFile {
									publicURL
									childImageSharp {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}

						... on TIMACUM_ComponentContentText {
							id
							Text
						}

						... on TIMACUM_ComponentContentFramework {
							id
							SectionTitle
							Title
							PoweredText
							ImageLeftTitle
							ImageRightTitle
							List {
								Title
							}
							FImage {
								Image {
									url
									alternativeText
									caption
									imageFile {
										publicURL
										childImageSharp {
											fluid(maxWidth: 1920, quality: 100) {
												...GatsbyImageSharpFluid
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`)

	const { Seo } = data.timacum.page

	return (
		<Layout location={props.location} customCTA="blockchain">
			<SEO data={Seo} />
			<Container>
				<WHTitleText
					as={TitleText}
					heading={data.timacum.page.Fields[0].Title}
					text={data.timacum.page.Fields[0].Text}
					level={1}
					wysiwyg
				/>

				<WHImage
					loading="lazy"
					src={data.timacum.page.Fields[1].Image.imageFile.publicURL}
					alt={data.timacum.page.Fields[1].Image.alternativeText}
					title={data.timacum.page.Fields[1].Image.caption}
				/>

				<Right
					as={ImageRightContentCta}
					fields={data.timacum.page.Fields[2]}
					level={2}
				/>

				<Left
					as={ImageLeftContentCta}
					fields={data.timacum.page.Fields[3]}
					level={2}
				/>

				<SamePage
					as={TitleText}
					heading={data.timacum.page.Fields[4].Title}
					text={data.timacum.page.Fields[4].Text}
					level={2}
					wysiwyg
				/>

				<WHImage
					loading="lazy"
					src={data.timacum.page.Fields[5].Image.imageFile.publicURL}
					alt={data.timacum.page.Fields[5].Image.alternativeText}
					title={data.timacum.page.Fields[5].Image.caption}
				/>

				<DecP as={Paragraph}>{data.timacum.page.Fields[6].Text}</DecP>

				<Framework>
					<Heading level={2}>
						{data.timacum.page.Fields[7].SectionTitle}
					</Heading>

					<FContainer>
						{data.timacum.page.Fields[7].FImage.map((image, index) => {
							return (
								<FWrapper key={'fimage' + index} className={'image' + index}>
									<img
										loading="lazy"
										src={image.Image.imageFile.publicURL}
										alt={image.Image.alternativeText}
										title={image.Image.caption}
									/>
									{(() => {
										if (index === 0) {
											return <p>{data.timacum.page.Fields[7].ImageLeftTitle}</p>
										} else if (index === 4) {
											return (
												<p>{data.timacum.page.Fields[7].ImageRightTitle}</p>
											)
										}
									})()}
								</FWrapper>
							)
						})}
					</FContainer>
				</Framework>

				<Right
					className="custom"
					as={ImageRightContentCta}
					fields={data.timacum.page.Fields[8]}
					level={2}
				/>

				<Left
					className="embracing"
					as={ImageLeftContentCta}
					fields={data.timacum.page.Fields[9]}
					level={2}
				/>

				<Right
					className="powered"
					as={ImageRightContentCta}
					fields={data.timacum.page.Fields[10]}
					level={2}
				/>

				<Left
					style={{ marginBottom: 0 }}
					as={ImageLeftContentCta}
					fields={data.timacum.page.Fields[11]}
					level={2}
					baseLink
				/>
			</Container>
		</Layout>
	)
})

export default WhiteLabel
